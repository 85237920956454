<template>
  <div class="merchantcenter_page">
    <div
      style="
        box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
      "
    >
      <div class="page_title">{{ language.Password_Settings }}</div>
      <!-- 登录密码 -->
      <div style="display: flex; margin-top: 30px">
        <div style="display: flex; align-items: center; width: 150px">
          {{ language.Login_password }}：********
        </div>
        <div style="margin-left: 30px; cursor: pointer" @click="open()">
          <el-button type="primary">{{ language.Change_Password }}</el-button>
        </div>
      </div>
      <!-- 支付密码 -->
      <div style="display: flex; margin-top: 30px">
        <div style="display: flex; align-items: center; width: 150px">
          {{ language.paymentpassword }}：{{
            is_pay_pwd ? "********" : language.Passwordnotset
          }}
        </div>
        <div style="margin-left: 30px; cursor: pointer" @click="paymentpasswordchange()">
          <el-button type="primary">{{
            is_pay_pwd ? language.Change_Password : language.ConfigureCiphers
          }}</el-button>
        </div>
      </div>
      <!-- 弹窗修改密码 -->
      <el-dialog
        :close-on-click-modal="false"
        :title="language.Password_Settings"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
        center
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 40px;
          "
        >
          <!-- 旧密码 -->
          <div style="width: 50%; position: relative">
            <div>{{ language.Old_password }}</div>
            <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
            <el-input
              style="box-sizing: border-box"
              v-model="Old_password"
              :type="passwordicon ? 'password' : 'text'"
              placeholder=""
            ></el-input>
            <span v-if="passwordicon" style="" @click="eyebtn()" class="iconeye3"></span>
            <span v-else style="" @click="eyebtn()" class="iconeye4"></span>
          </div>
          <!-- 新密码 -->
          <div style="margin-top: 20px; width: 50%; position: relative">
            <div>{{ language.New_password }}</div>
            <!-- <el-input style="" v-model="New_password" placeholder=""></el-input> -->
            <el-input
              style="box-sizing: border-box"
              v-model="New_password"
              :type="passwordicon2 ? 'password' : 'text'"
              placeholder=""
            ></el-input>
            <span
              v-if="passwordicon2"
              style=""
              @click="eyebtn2()"
              class="iconeye3"
            ></span>
            <span v-else style="" @click="eyebtn2()" class="iconeye4"></span>
          </div>
          <!-- 确认密码 -->
          <div style="margin-top: 20px; width: 50%; position: relative">
            <div>{{ language.Confirm_Password }}</div>
            <!-- <el-input style="" v-model="Confirm_Password" placeholder=""></el-input> -->
            <el-input
              style="box-sizing: border-box"
              v-model="Confirm_Password"
              :type="passwordicon3 ? 'password' : 'text'"
              placeholder=""
            ></el-input>
            <span
              v-if="passwordicon3"
              style=""
              @click="eyebtn3()"
              class="iconeye3"
            ></span>
            <span v-else style="" @click="eyebtn3()" class="iconeye4"></span>
          </div>
          <div style="margin-top: 50px">
            <el-button style="" type="primary" @click="changebtn()">{{
              language.Confirm_modification
            }}</el-button>
          </div>
        </div>
      </el-dialog>

      <!-- 支付密码修改 -->
      <el-dialog
        :close-on-click-modal="false"
        :title="language.Password_Settings"
        :visible.sync="paymentpasswordchangedialogVisible"
        width="30%"
        :before-close="handleClose2"
        center
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 40px;
          "
        >
          <!-- 支付密码 -->
          <div style="width: 50%; position: relative">
            <div>{{ language.paymentpassword }}</div>
            <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
            <el-input
              style="box-sizing: border-box"
              v-model="paymentpassword"
              :type="passwordicon4 ? 'password' : 'text'"
              placeholder=""
            ></el-input>
            <span
              v-if="passwordicon4"
              style=""
              @click="eyebtn4()"
              class="iconeye3"
            ></span>
            <span v-else style="" @click="eyebtn4()" class="iconeye4"></span>
          </div>
          <!-- 确认密码 -->
          <div style="margin-top: 20px; width: 50%; position: relative">
            <div>{{ language.Confirm_Password }}</div>
            <!-- <el-input style="" v-model="Confirm_Password" placeholder=""></el-input> -->
            <el-input
              style="box-sizing: border-box"
              v-model="Confirm_Password2"
              :type="passwordicon5 ? 'password' : 'text'"
              placeholder=""
            ></el-input>
            <span
              v-if="passwordicon5"
              style=""
              @click="eyebtn5()"
              class="iconeye3"
            ></span>
            <span v-else style="" @click="eyebtn5()" class="iconeye4"></span>
          </div>
          <div style="margin-top: 50px">
            <el-button style="" type="primary" @click="changebtn2()">{{
              language.Confirm_modification
            }}</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import rules from "../../common/js/rules";
import { useruppwd, groupgetRuleTreeList, usersetPayPwd, user } from "../../api/index.js";
export default {
  data() {
    return {
      language: "", //语言
      dialogVisible: false, //弹窗是否显示
      Old_password: "", //旧密码
      New_password: "", //新密码
      Confirm_Password: "", //确认密码
      passwordicon: true, //显示密码
      passwordicon2: true, //显示密码
      passwordicon3: true, //显示密码
      paymentpasswordchangedialogVisible: false,
      paymentpassword: "", //支付密码
      Confirm_Password2: "", //确定支付密码
      passwordicon4: true, //显示密码
      passwordicon5: true, //显示密码
      is_pay_pwd: "",
    };
  },
  created() {
    // 判断语言
    this.changelanguage();
    this.groupgetRuleTreeList();
    this.init();
  },
  watch: {
    // 判断语言
    "$store.state.language"() {
      this.changelanguage();
    },
  },

  methods: {
    init() {
      user().then((res) => {
        res = res.data;
        this.is_pay_pwd = res.userinfo.is_pay_pwd;
      });
    },
    groupgetRuleTreeList() {
      let userinfo = JSON.parse(localStorage.getItem("userinfo"));
      if (userinfo.group_id > 0) {
        let params = {
          groupId: userinfo.group_id,
        };
        groupgetRuleTreeList({}, params).then((res2) => {
          localStorage.setItem("adminNode", JSON.stringify(res2.data));
          let changerules = !this.$store.state.rules;
          this.$store.commit("changerules", changerules);
          this.rules = rules.rules().msg;
          if (!rules.rules().list.includes("/changepassword")) {
            this.$message({
              message: this.language.nopermission,
              type: "error",
              duration: 2000,
            });
            setTimeout(() => {
              this.$router.push(rules.rules().list[0]);
            }, 2000);
          }
        });
      }
      // console.log(rules.rules().list.includes("/changepassword"));
    },
    //密码隐藏及显示
    eyebtn() {
      this.passwordicon = !this.passwordicon;
    },
    eyebtn2() {
      this.passwordicon2 = !this.passwordicon2;
    },
    eyebtn3() {
      this.passwordicon3 = !this.passwordicon3;
    },
    eyebtn4() {
      this.passwordicon4 = !this.passwordicon4;
    },
    eyebtn5() {
      this.passwordicon5 = !this.passwordicon5;
    },
    // 确定修改支付密码
    changebtn2() {
      if (!this.paymentpassword) {
        this.$message({
          message: this.language.paymentpassword + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      if (!this.Confirm_Password2) {
        this.$message({
          message: this.language.Confirm_Password + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      let data = {
        paypwd: this.paymentpassword,
        paypwd2: this.Confirm_Password2,
      };
      usersetPayPwd(data).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: this.language.Edit_succeeded,
            type: "success",
          });
          this.paymentpasswordchangedialogVisible = false;
        }
      });
    },
    // 确认修改
    changebtn() {
      if (!this.Old_password) {
        this.$message({
          message: this.language.noOld_password + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      if (!this.New_password) {
        this.$message({
          message: this.language.noNew_password + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      if (!this.Confirm_Password) {
        this.$message({
          message: this.language.noConfirm_Password + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      let data = {
        oldPassword: this.Old_password,
        newPassword: this.New_password,
        passwordConfirmation: this.Confirm_Password,
      };
      useruppwd(data).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: this.language.Edit_succeeded,
            type: "success",
          });
          this.dialogVisible = false;
          window.localStorage.clear();
          this.$router.push("/Login");
        }
      });
    },
    // 获取语言
    changelanguage() {
      this.language = this.$units();
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    // 关闭确认支付
    handleClose2() {
      this.paymentpasswordchangedialogVisible = false;
    },
    // 打开弹窗
    open() {
      this.dialogVisible = true;
      this.Old_password = "";
      this.New_password = "";
      this.Confirm_Password = "";
    },
    // 编辑密码
    paymentpasswordchange() {
      this.Confirm_Password2 = "";
      this.paymentpassword = "";
      this.paymentpasswordchangedialogVisible = true;
    },
  },
};
</script>
<style scoped>
.merchantcenter_page {
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.iconeye3 {
  display: inline-block;
  width: 33px;
  height: 22px;
  position: absolute;
  z-index: 9;
  top: 27px;
  cursor: pointer;
  right: 0;
  background: url("../../static/icon-login.png") no-repeat 0 -43px;
}

.iconeye4 {
  display: inline-block;
  width: 33px;
  height: 22px;
  position: absolute;
  z-index: 9;
  top: 27px;
  cursor: pointer;
  right: 0;
  background: url("../../static/icon-login.png") no-repeat -55px -43px;
}
.page_title {
  font-size: 26px;
  font-weight: 600;
}

/* * >>> .el-input__inner{
    border: none;
    border-bottom: 1px solid #000000;
    border-radius: 0px;
    height: 30px;
    line-height: 30px;
} */
</style>
